import React, {useState} from "react";
import quickAddConfig from "../../config/quickAdd";

const QuickActions = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={`quick-actions ${isOpen ? "quick-actions--open" : ""}`}>
            <div className="quick-actions__overlay"/>
            <button className="btn btn--circle quick-actions__button" onClick={() => setIsOpen(!isOpen)}>
                <span>+</span>
            </button>
            <ul className={"quick-actions__list"}>
                {quickAddConfig.map(number => (
                    <li key={number}>
                        <button
                            className={`btn btn--circle ${false ? "disabled" : ""}`}
                            // onClick={(e) => addThePushups(e, number)}
                        >
                            + {number}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
};

export default QuickActions;