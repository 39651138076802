import React from "react";
import ProgressBar from "../ProgressBar";

const DashboardProgress = () => {
    const progressBars = [{
        label: "GTD - Getting things done!",
        progress: 85
    }, {
        label: "🎧 Wim Hof: Ice Man",
        progress: 14
    }, {
        label: "On Talking Terms With Dogs: Calming Signals (Konejšivé signály)",
        progress: 100
    }];

    return (
        <section>
            <h3>Progress</h3>
            {progressBars.map((progressBar, index) => (<ProgressBar key={index} data={progressBar}/>))}
        </section>
    );
}

export default DashboardProgress