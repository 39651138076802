import React from "react";

const Header = ({isDashboard, pageTitle = "Dashboard"}) => {
    return (
        <header>
            <a href={"/"} className={"header__logo"}>
                {isDashboard ?
                    <h1>Dashboard</h1> : <h2>Dashboard</h2>
                }
            </a>
            {!isDashboard &&
            <h1 className={"page-title"}>{pageTitle}</h1>
            }
            <h3>By <a href="https://chomat.tech">chomat.tech</a></h3>
        </header>
    )
}

export default Header;